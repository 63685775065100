/* eslint-disable @next/next/no-img-element */
import { Dialog, Transition } from "@headlessui/react";
import { LoadingContainer } from "components/LoadingContainer";
import { useRouter } from "next/router";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendServerAnalytics } from "services/analytics";
import { topicActions } from "stores/topic/topic.reducer";
import { IStore } from "stores/types";
import { CourseRegistration } from "./CourseRegistration";
import { Heading } from "./Heading";
import { Sections } from "./Sections";

export const CoursePopup = (): JSX.Element => {
  const router = useRouter();
  const { loading, topicPopupOpen, currentTopic } = useSelector(
    (store: IStore) => store.topic
  );
  const [view, setView] = useState<"overview" | "registration">("overview");
  const dispatch = useDispatch();

  const handleRegister = async () => {
    setView("registration");
    await sendServerAnalytics("click-course-registration", "course", {
      course_id: currentTopic?.id,
      title: currentTopic?.title,
    });
  };

  const handleContinueLearning = async () => {
    await sendServerAnalytics("click-continue-course", "course", {
      course_id: currentTopic?.id,
      title: currentTopic?.title,
    });
    router.push("/topic/" + currentTopic?.slug);
  };

  return (
    <>
      <LoadingContainer spinning={loading} full={true}>
        <Transition appear show={topicPopupOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto"
            onClose={() => dispatch(topicActions.setTopicPopupOpen(false))}
          >
            <div className="min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-50" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block w-full max-w-2xl overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-l-2xl">
                  <LoadingContainer spinning={loading} full={true}>
                    <div className="flex flex-col">
                      <div className="">
                        <img
                          className="w-full h-auto rounded-t-sm"
                          src={
                            process.env.NEXT_PUBLIC_BACKEND_URL +
                            (currentTopic?.image ?? "")
                          }
                          alt="course image"
                          style={{
                            //   aspectRatio: "1.6",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      {view === "overview" ? (
                        <div className="flex flex-col px-8 space-y-12 py-9">
                          <Heading />
                          {currentTopic?.lesson_type === "LESSON" && (
                            <Sections />
                          )}
                          {(currentTopic?.lesson_type === "ONLINE" ||
                            currentTopic?.lesson_type === "OFFLINE") && (
                            <div
                              className="flex flex-col space-y-4"
                              dangerouslySetInnerHTML={{
                                __html: currentTopic?.short_description ?? "",
                              }}
                            ></div>
                          )}
                          <div className="flex flex-row justify-center">
                            <button
                              type="button"
                              className="flex justify-center w-full px-4 py-2 text-lg font-medium text-white border border-transparent rounded-l-lg max-w-max bg-primary-pink hover:bg-primary-bolder hover:border-primary-bolder"
                              onClick={
                                currentTopic?.is_enroll
                                  ? handleContinueLearning
                                  : handleRegister
                              }
                            >
                              {currentTopic?.is_enroll
                                ? "Xem tiếp"
                                : "Tham gia"}
                            </button>
                          </div>
                        </div>
                      ) : (
                        <CourseRegistration
                          setLoading={(val: boolean) =>
                            dispatch(topicActions.setLoading(val))
                          }
                          course={currentTopic}
                          setView={setView}
                        />
                      )}
                    </div>
                  </LoadingContainer>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </LoadingContainer>
    </>
  );
};
