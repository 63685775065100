import { FormEvent, useReducer } from "react";

interface QuestionProps {
  questions: any[]; // TO-DO: add question interface
  onSubmit?: any;
  view: "single" | "list";
}

export const Question = ({ question, idx, value, onChange }: any) => {
  return (
    <div className="flex flex-col space-y-2">
      <div className="text-lg font-medium text-primary-pink">
        {idx + 1}. {question.title}
      </div>
      {question.question_type === "TEXT" && (
        <div>
          <textarea
            rows={4}
            name="comment"
            id="comment"
            className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            defaultValue={""}
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />
        </div>
      )}
      {question.question_type === "SINGLE_CHOICE" && (
        <div className="flex flex-col space-y-1">
          {(question.answers ?? []).map((ans: any, idx: number) => (
            <div
              className="flex items-center"
              key={`ans-${ans.id}-${question.id}`}
            >
              <input
                name="notification-method"
                type="radio"
                // defaultChecked={notificationMethod.id === 'email'}
                className="w-4 h-4 border-gray-300 text-primary-pink focus:ring-primary-pink"
                onChange={(e) => {
                  if (e.target.checked) {
                    onChange(idx + 1);
                  }
                }}
              />
              <label className="block ml-3 text-sm font-medium text-gray-700">
                {ans.text}
              </label>
            </div>
          ))}
        </div>
      )}
      {question.question_type === "MULTIPLE_CHOICES" && (
        <div className="flex flex-col space-y-1">
          {(question.answers ?? []).map((ans: any, idx: number) => (
            <div
              className="relative flex items-start"
              key={`ans-${question.id}-${ans.id}`}
            >
              <div className="flex items-center h-5">
                <input
                  id="comments"
                  aria-describedby="comments-description"
                  name="comments"
                  type="checkbox"
                  className="w-4 h-4 border-gray-300 rounded text-primary-pink focus:ring-primary-pink"
                  onChange={(e) => {
                    value = value ?? [];
                    const current_id = idx + 1;
                    if (e.target.checked) {
                      if (!(value as number[]).includes(current_id)) {
                        value.push(current_id);
                      }
                    } else {
                      value = value.filter((val: number) => val !== current_id);
                    }

                    onChange(value);
                  }}
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="comments" className="font-medium text-gray-700">
                  {ans.text}
                </label>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const ListQuestions = ({
  questions,
  onSubmit,
  view,
}: {
  questions: any[];
  onSubmit: any;
  view: "list" | "single";
}) => {
  const [formValue, dispatch] = useReducer((state: any, action: any) => {
    switch (action.action) {
      case "VALUE_CHANGE":
        state[action.payload.key] = action.payload.value;
        break;
      default:
        console.log("No action found");
    }
    return { ...state };
  }, {});

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSubmit(formValue);
  };

  return (
    <div className="flex flex-col space-y-6">
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col space-y-6">
          {view === "list" &&
            questions.map((question: any, idx: number) => (
              <Question
                key={question.id}
                question={question}
                idx={idx}
                value={formValue[question.id]}
                onChange={(value: any) => {
                  dispatch({
                    action: "VALUE_CHANGE",
                    payload: {
                      key: question.id,
                      value,
                    },
                  });
                }}
              />
            ))}
        </div>
        <div className="flex flex-row justify-center w-full mt-4">
          <button
            type="submit"
            className="flex self-center justify-center h-10 px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md min-w-max bg-primary-pink hover:bg-primary-bolder hover:border-primary-bolder"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export const Questions = (props: QuestionProps) => {
  return (
    <div>
      <ListQuestions
        view={props.view}
        questions={props.questions}
        onSubmit={props.onSubmit}
      />
    </div>
  );
};
