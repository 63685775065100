import { ClockIcon } from "@heroicons/react/outline";
import {
  StarIcon,
  UserIcon,
  VideoCameraIcon,
  VolumeUpIcon,
} from "@heroicons/react/solid";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { IStore } from "stores/types";

export const Heading = (): JSX.Element => {
  const { currentTopic } = useSelector((store: IStore) => store.topic);
  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-col items-start space-y-2 ">
        <div className="px-1 py-px text-lg rounded bg-secondary max-w-max">
          {currentTopic?.category?.title}
        </div>
        <div className="text-3xl font-semibold text-primary-pink">
          {currentTopic?.title ?? ""}
        </div>
        <div className="text-lg text-gray-600 flont-semibold">
          {currentTopic?.instructor.full_name}
        </div>
      </div>
      {currentTopic?.lesson_type === "LESSON" ? (
        <div className="flex flex-col space-y-2 text-sm">
          <div className="flex flex-row space-x-2 text-smoke">
            <StarIcon height={20} width={20} />
            <span>Độ khó mức {currentTopic?.level}</span>
          </div>
          <div className="flex flex-row space-x-2 text-smoke">
            <UserIcon height={20} width={20} />
            <span>{currentTopic?.registration_count ?? 0} người đã học</span>
          </div>
          <div className="flex flex-row space-x-2 text-smoke">
            <VideoCameraIcon height={20} width={20} />
            <span>Video dài 20 phút</span>
          </div>
          <div className="flex flex-row space-x-2 text-smoke">
            <VolumeUpIcon height={20} width={20} />
            <span>Tiếng Việt</span>
          </div>
        </div>
      ) : (
        <div className="flex flex-col space-y-2 text-sm">
          <div className="flex flex-row space-x-2 text-smoke">
            <ClockIcon height={20} width={20} />
            <span>
              Thời gian:{" "}
              {currentTopic?.date_time
                ? DateTime.fromISO(currentTopic.date_time).toFormat(
                    "yyyy/MM/dd HH:mm"
                  )
                : ""}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
