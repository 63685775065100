/* eslint-disable @next/next/no-img-element */
// import { ThumbUpIcon, UserIcon } from "@heroicons/react/outline";
import Image from "next/image";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { sendServerAnalytics } from "services/analytics";
import { topicActions } from "stores/topic/topic.reducer";
import { topicSagaActions } from "stores/topic/topic.saga";

const CourseItem = (props: any): JSX.Element => {
  const router = useRouter();
  const dispatch = useDispatch();
  const lessonType = useMemo(() => {
    if (props.data.lesson_type === "LESSON") {
      return "Bài học";
    }

    if (props.data.lesson_type === "OFFLINE") {
      return "Offline event";
    }

    if (props.data.lesson_type === "ONLINE") {
      return "Online event";
    }

    if (props.data.lesson_type === "LIVE") {
      return "Bải học trực tuyến";
    }
  }, [props.data.lesson_type]);
  return (
    <div
      style={{ height: 420 }}
      className="flex flex-col w-auto overflow-hidden bg-white rounded-sm rounded-l-lg"
    >
      <div className="relative w-full">
        <img
          className="w-full h-40 rounded-t-sm"
          src={props?.data?.image ?? "/images/logo-vuong.webp"}
          alt="logo"
          style={{
            aspectRatio: "1.3",
            objectFit: "cover",
          }}
        />

        <div className="absolute flex flex-row space-x-2 bottom-1 right-1">
          {/* <span className="px-3 py-1 text-xs rounded-sm bg-secondary">
            {lessonType}
          </span> */}
          {/* <span className="px-3 py-1 text-xs rounded-sm bg-secondary">
            {lessonType}
          </span> */}
        </div>
      </div>
      <div className="flex flex-col justify-between h-full p-3">
        <div className="flex flex-col">
          <div
            style={{ minHeight: "135px" }}
            className="flex flex-col space-y-1"
          >
            <div className="flex flex-row items-start justify-between space-x-2 text-lg">
              <div
                className="flex-1 cursor-pointer"
                onClick={
                  async () => {
                    dispatch(
                      topicSagaActions.getTopic({ slug: props.data?.slug })
                    );
                    dispatch(topicActions.setTopicPopupOpen(true));

                    await sendServerAnalytics(
                      "click-course-item-widget-title",
                      "course",
                      {
                        course_id: props?.data?.id,
                        title: props?.data?.title,
                        point: props?.data?.point,
                      }
                    );
                  }

                  // router?.push(`/topic/${props?.data?.slug}`)
                }
              >
                {props?.data?.title}
              </div>
              {/* <div className="flex flex-col justify-end space-y-1">
                <div className="p-1 text-xs text-center text-white rounded-sm bg-primary-pink">
                  {lessonType}
                </div>
                <div className="text-sm font-normal text-gray-600">
                  <span className="">{props?.data?.instructor?.full_name}</span>
                </div>
              </div> */}
            </div>
            <div className="flex flex-row items-center justify-between space-y-1">
              <div className="text-sm font-normal text-gray-600">
                <span className="">{props?.data?.instructor?.full_name}</span>
              </div>
              <div className="p-1 text-xs text-center text-white rounded-sm bg-primary-pink">
                {lessonType}
              </div>
            </div>
            <div
              className="h-10 overflow-y-hidden text-sm font-light text-gray-500 text-ellipsis line-"
              dangerouslySetInnerHTML={{
                __html:
                  props?.data?.short_description || "Chỗ này chưa có mô tả",
              }}
            ></div>
          </div>
          {/* <div className="flex flex-row my-2 space-x-6 text-sm font-light text-gray-500">
            <div className="flex flex-row items-center space-x-1">
              <UserIcon width={16} height={16} />
              <span>{props.data?.registration_count}</span>
            </div>
            <div className="flex flex-row items-center space-x-1">
              <ThumbUpIcon width={14} height={14} />
              <span>100% (3.2K)</span>
            </div>
          </div> */}
          <div className="flex flex-row items-center mt-3 space-x-1">
            <div className="flex flex-row justify-center space-x-1">
              {props?.data?.point > 3 ? (
                <div className="flex flex-row items-center space-x-2">
                  <div>{props?.data?.point}</div>
                  <div className="w-6">
                    <Image
                      src="/images/to.png"
                      //   width="200px"
                      height="15"
                      width="20"
                      layout="responsive"
                      alt="ga-welcome"
                    />
                  </div>
                </div>
              ) : (
                Array(props?.data?.point)
                  .fill(0)
                  .map((_, idx) => (
                    // <span key={idx} className="text-xl">
                    //   {/* 🍜 */}
                    //   🥘
                    // </span>
                    <div key={"to-" + idx} className="w-6 ">
                      {/* 🍜 */}
                      {/* 🥘 */}
                      <Image
                        src="/images/to.png"
                        //   width="200px"
                        height="15"
                        width="20"
                        layout="responsive"
                        alt="ga-welcome"
                      />
                    </div>
                  ))
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <button
            className="items-center justify-center w-full h-12 mt-4 text-lg font-medium text-white duration-300 border-none rounded-sm rounded-l-lg outline-none bg-primary-pink hover:bg-indigo-700"
            onClick={
              async () => {
                await sendServerAnalytics(
                  "click-course-item-widget-button",
                  "course",
                  {
                    course_id: props?.data?.id,
                    title: props?.data?.title,
                    point: props?.data?.point,
                    is_enroll: props?.data?.is_enroll,
                  }
                );

                if (props.data?.is_enroll) {
                  router.push("/topic/" + props.data?.slug);
                  return;
                }

                dispatch(topicSagaActions.getTopic({ slug: props.data?.slug }));
                dispatch(topicActions.setTopicPopupOpen(true));
              }

              // router?.push(`/topic/${props?.data?.slug}`)
            }
          >
            {props.data?.is_enroll ? "Xem tiếp" : "Xem chi tiết"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CourseItem;
