import { useSelector } from "react-redux";
import { IStore } from "stores/types";

const SessionItem = ({
  item,
  idx,
}: {
  item: any;
  idx: number;
}): JSX.Element => {
  return (
    <div className="flex flex-col space-x-2">
      <div className="text-lg text-primary-pink">
        {idx}. {item.title}
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: item?.short_description ?? "",
        }}
      ></div>
    </div>
  );
};

export const Sections = (): JSX.Element => {
  const { currentTopic } = useSelector((store: IStore) => store.topic);
  return (
    <div className="flex flex-col space-y-4">
      <div className="text-xl font-semibold text-gray-700">Sections:</div>
      <div className="flex flex-col justify-start space-y-4">
        {currentTopic?.sessions.map((session: any, idx: number) => (
          <SessionItem key={session.id} item={session} idx={idx + 1} />
        ))}
      </div>
    </div>
  );
};
