import axios from "axios";
import { notify } from "components/Notification";
import { Questions } from "components/Questions";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { sendServerAnalytics } from "services/analytics";
import { IStore } from "stores/types";

const EnrollForm = ({
  setView,
  setLoading,
  course,
}: {
  setView: any;
  setLoading: any;
  course: any;
}): JSX.Element => {
  console.log({
    course,
  });
  const router = useRouter();
  const [view, setViews] = useState<
    "Question" | "AnswerTrue" | "AnswerFalse" | "Accept"
  >("Question");
  const handleEnroll = async () => {
    try {
      setLoading?.(true);
      const response = await axios.post(
        `/api/v1/enroll/${course.id}`,
        { id: course.id },
        {
          withCredentials: true,
        }
      );

      const { data = {} } = response.data;
      if (!data.success) {
        notify({
          content: data.message ?? "",
        });
      } else {
        notify({
          content: "Đăng ký lớp học thành công",
        });
      }

      await sendServerAnalytics("enroll-successful", "course", {
        course_id: course?.id,
        title: course?.title,
        point: course?.point,
      });

      //   setView(false);
      router.push("/topic/" + course?.slug);
    } catch (error) {
      await sendServerAnalytics("enroll-error", "course", {
        course_id: course?.id,
        title: course?.title,
        point: course?.point,
      });
      notify({
        content: "Đã có lỗi xảy ra, hãy thử lại",
      });
    }
    setLoading?.(false);
  };

  useEffect(() => {
    if (course?.pre_questions?.length > 0) {
      setViews("Question");
      return;
    }
    setViews("Accept");
  }, [course]);

  const handleSubmitQuestions = async (values: any) => {
    console.log("submit values", {
      values,
    });
    const params = Object.keys(values).map((key: string) => ({
      id: key,
      answer: values[key],
    }));

    try {
      const res = await axios.post("/api/v1/question-answers", params, {
        withCredentials: true,
      });
      console.log({
        response: res.data,
      });

      const results: any[] = res.data?.data?.results ?? [];
      if (results.length === 0) {
        notify({
          content: "Hãy chọn câu trả lời",
        });
        return;
      }

      if (results.every((result) => result.result)) {
        setViews("AnswerTrue");
        return;
      }

      setViews("Accept");
    } catch (error) {
      notify({
        content: "Đã có lỗi xảy ra, xin hãy thử lại",
      });
    }
  };

  return (
    <div className="flex flex-col p-10 space-y-8">
      {view === "Question" && (
        <>
          <div className="text-xl text-gray-600">
            Trước khi bắt đầu, hãy trả lời những câu hỏi sau đây để kiểm tra
            kiến thức của bạn nhé.
          </div>
          <Questions
            view="list"
            questions={course?.pre_questions}
            onSubmit={handleSubmitQuestions}
          />
        </>
      )}
      {view === "AnswerTrue" && (
        <>
          <div className="text-lg text-gray-600">
            Chúc mừng bạn đã trả lời chính xác tất cả câu hỏi. Bạn có thể tiếp
            tục đăng ký hoặc chọn các bài học cao hơn
          </div>
          <div className="flex flex-row space-x-4">
            <div className="flex-1">
              <button
                type="button"
                className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent shadow-sm rounded-l-md btn bg-primary-pink hover:bg-indigo-700 focus:outline-none focus:ring-offset-2 focus:border-none"
                onClick={handleEnroll}
              >
                Tiếp tục đăng ký
              </button>
            </div>
            <div className="flex-1">
              <button
                type="button"
                className="w-full btn"
                onClick={() => setView?.("overview")}
              >
                Trở lại
              </button>
            </div>
            {/* Các bài học gợi ý */}
          </div>
        </>
      )}
      {view === "Accept" && (
        <>
          <div className="text-lg">
            Bạn sẽ sử dụng <strong>{course.point} tô</strong> để đăng ký lớp học
            này?
          </div>
          <div className="flex flex-row space-x-4">
            <div className="flex-1">
              <button
                type="button"
                className="flex justify-center w-full px-4 py-3 font-medium text-white uppercase border border-transparent shadow-sm rounded-l-md bg-primary-pink hover:bg-indigo-700 focus:outline-none focus:ring-offset-2 focus:border-none"
                onClick={handleEnroll}
              >
                Xác nhận
              </button>
            </div>
            <div className="flex-1">
              <button
                type="button"
                className="flex justify-center w-full px-4 py-3 font-medium text-white uppercase bg-gray-700 border border-transparent shadow-sm rounded-l-md focus:outline-none focus:ring-offset-2 focus:border-"
                onClick={() => setView?.("overview")}
              >
                Hủy
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export const CourseRegistration = ({
  setLoading,
  course,
  setView,
}: any): JSX.Element => {
  const { user } = useSelector((store: IStore) => store.user);

  // console.log({ user, course });

  const canEnroll = useMemo(() => {
    if (user) {
      return (
        (user?.credit_amount ?? 0) >= course.point ||
        (user?.point_amount ?? 0) > 100
      );
    }

    return false;
  }, [user, course]);

  const errorMessage = useMemo(() => {
    if (!user) {
      return "Bạn hãy đăng nhập hoặc đăng ký tài khoản để tham gia lớp học";
    }
    return "Bạn chưa đủ điều kiện để tham dự lớp này, hãy nạp thêm tô để bắt đầu bài học";
  }, [user]);

  return (
    <div className="mt-8">
      {canEnroll ? (
        <EnrollForm setView={setView} setLoading={setLoading} course={course} />
      ) : (
        <div className="flex flex-col items-center p-12 space-y-6">
          <div>{errorMessage}</div>
          <button
            type="button"
            className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent shadow-sm rounded-l-md max-w-max btn bg-primary-pink hover:bg-indigo-700 focus:outline-none focus:ring-offset-2 focus:border-none"
            onClick={() => setView("overview")}
          >
            Quay lại
          </button>
        </div>
      )}
    </div>
  );
};
